<template>
  <div class="info-list">
    <p>{{ row.title }}</p>
    <template v-if="row.creator">
      <p>{{ row.creator }}</p>
    </template>
    <template v-if="row.year">
      <p>{{ row.year }}</p>
    </template>
    <template v-if="row.category || row.cate">
      <p>{{ row.category || row.cate }}</p>
    </template>
    <template v-if="row.size">
      <p>{{ row.size }}</p>
    </template>
    <template v-if="row.serial">
      <p>典藏編號 {{ row.serial }}</p>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    row: {
      type: Object,
    },
  },
  setup(props) {},
};
</script>
